import api from '../api.js';
import moment from 'moment';
import {config, getCookie, redirect, setCookie, trackEvent, trackUser, updateAmplitudeUser} from "../helpers";
import Api from "../api.js";

// User
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_PAYMENTS = 'FETCH_USER_PAYMENTS';

// Menu
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';
export const FETCH_MENU_REPORT = 'FETCH_MENU_REPORT';
export const UPDATE_MENU_EATEN_KCAL = 'UPDATE_MENU_EATEN_KCAL';

// Diary
export const FETCH_DIARY_SUCCESS = 'FETCH_DIARY_SUCCESS';
export const FETCH_DIARY_FAILURE = 'FETCH_DIARY_FAILURE';

// Date
export const UPDATE_MENU_DATE = 'UPDATE_MENU_DATE';

// Recipes
export const FETCH_RECIPES_BEGIN = 'FETCH_RECIPES_BEGIN';
export const FETCH_RECIPES_SUCCESS = 'FETCH_RECIPES_SUCCESS';
export const FETCH_RECIPES_FAILURE = 'FETCH_RECIPES_FAILURE';
export const FETCH_USER_RECIPES_SUCCESS = 'FETCH_USER_RECIPES_SUCCESS';
export const FETCH_USER_RECIPES_FAILURE = 'FETCH_USER_RECIPES_FAILURE';
export const FETCH_POPULAR_RECIPES_SUCCESS = 'FETCH_POPULAR_RECIPES_SUCCESS';
export const FETCH_POPULAR_RECIPES_FAILURE = 'FETCH_POPULAR_RECIPES_FAILURE';
export const FETCH_NEW_RECIPES_SUCCESS = 'FETCH_NEW_RECIPES_SUCCESS';
export const FETCH_NEW_RECIPES_FAILURE = 'FETCH_NEW_RECIPES_FAILURE';
export const SET_KEYWORD = 'SET_KEYWORD';

// Access
export const FETCH_ACCESS_SUCCESS = 'FETCH_ACCESS_SUCCESS';
export const FETCH_ACCESS_FAILURE = 'FETCH_ACCESS_FAILURE';

// Family members
export const FETCH_FAMILY_MEMBERS_SUCCESS = 'FETCH_FAMILY_MEMBERS_SUCCESS';
export const FETCH_FAMILY_MEMBERS_FAILURE = 'FETCH_FAMILY_MEMBERS_FAILURE';

// Weight log
export const FETCH_WEIGHT_LOG_SUCCESS = 'FETCH_WEIGHT_LOG_SUCCESS';
export const FETCH_WEIGHT_LOG_FAILURE = 'FETCH_WEIGHT_LOG_FAILURE';

// Water log
export const FETCH_WATER_LOG_SUCCESS = 'FETCH_WATER_LOG_SUCCESS';
export const FETCH_WATER_LOG_FAILURE = 'FETCH_WATER_LOG_FAILURE';

// Daily task
export const FETCH_DAILY_TASK_SUCCESS = 'FETCH_DAILY_TASK_SUCCESS';
export const FETCH_DAILY_TASK_FAILURE = 'FETCH_DAILY_TASK_FAILURE';

// Goal
export const FETCH_GOAL_SUCCESS = 'FETCH_GOAL_SUCCESS';
export const FETCH_GOAL_FAILURE = 'FETCH_GOAL_FAILURE';

// Sizes
export const FETCH_SIZES_LOG_SUCCESS = 'FETCH_SIZES_LOG_SUCCESS';
export const FETCH_SIZES_LOG_FAILURE = 'FETCH_SIZES_LOG_FAILURE';

// Freeware ingredients
export const FETCH_FREEWARE_SUCCESS = 'FETCH_FREEWARE_SUCCESS';
export const FETCH_FREEWARE_FAILURE = 'FETCH_FREEWARE_FAILURE';

// Shopping list
export const FETCH_SHOPPING_LIST_SUCCESS = 'FETCH_SHOPPING_LIST_SUCCESS';
export const FETCH_SHOPPING_LIST_FAILURE = 'FETCH_SHOPPING_LIST_FAILURE';
export const FETCH_SHOPPING_LIST_CATEGORIES = 'FETCH_SHOPPING_LIST_CATEGORIES';

export const IS_CHEAT_MEAL_AVAILABLE_SUCCESS = 'IS_CHEAT_MEAL_AVAILABLE_SUCCESS';
export const IS_CHEAT_MEAL_AVAILABLE_FAILURE = 'IS_CHEAT_MEAL_AVAILABLE_FAILURE';

// Daily activities
export const FETCH_DAILY_ACTIVITIES_SUCCESS = 'FETCH_DAILY_ACTIVITIES_SUCCESS';
export const FETCH_DAILY_ACTIVITIES_FAILURE = 'FETCH_DAILY_ACTIVITIES_FAILURE';

// Notifications
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

// Physical activities
export const FETCH_PHYSICAL_ACTIVITIES_SUCCESS = 'FETCH_PHYSICAL_ACTIVITIES_SUCCESS';
export const FETCH_PHYSICAL_ACTIVITIES_FAILURE = 'FETCH_PHYSICAL_ACTIVITIES_FAILURE';

// Products
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

// Global APP settings
export const SHOW_CHAT = 'SHOW_CHAT';
export const BLOG_FILTER = 'BLOG_FILTER';
export const BLOG_KEYWORD = 'BLOG_KEYWORD';
export const BANNERS = 'BANNERS';
export const CUSTOM_UNITS = 'CUSTOM_UNITS';
export const APP_NAV_MENU = 'APP_NAV_MENU';
export const ADVENT_CALENDER = 'ADVENT_CALENDER';
export const DARK_MODE = 'DARK_MODE';

// Favorite authors
export const FETCH_AUTHORS_BEGIN = 'FETCH_AUTHORS_BEGIN';
export const FETCH_AUTHORS_SUCCESS = 'FETCH_AUTHORS_SUCCESS';
export const FETCH_AUTHORS_FAILURE = 'FETCH_AUTHORS_FAILURE';

// Blog feed
export const FETCH_BLOG_FEED_BEGIN = 'FETCH_BLOG_FEED_BEGIN';
export const FETCH_BLOG_FEED_SUCCESS = 'FETCH_BLOG_FEED_SUCCESS';
export const FETCH_BLOG_FEED_FAILURE = 'FETCH_BLOG_FEED_FAILURE';

// Levels
export const FETCH_USER_LEVELS_SUCCESS = 'FETCH_USER_LEVELS_SUCCESS';
export const FETCH_USER_LEVELS_FAILURE = 'FETCH_USER_LEVELS_FAILURE';

// Quick start
export const FETCH_QUICK_START_SUCCESS = 'FETCH_QUICK_START_SUCCESS';
export const FETCH_QUICK_START_FAILURE = 'FETCH_QUICK_START_FAILURE';

/**
 *  User
 */

export const fetchUserSuccess = (user) => ({
    type: FETCH_USER_SUCCESS,
    payload: {user}
});

export const fetchUserFailure = (error) => ({
    type: FETCH_USER_FAILURE,
    payload: {error}
});

export const fetchUserPayments = (data) => ({
    type: FETCH_USER_PAYMENTS,
    payload: {data}
});


export const getUserData = (callback) => {
    return (dispatch) => {
        return api.get('/v1/user').then(response => {
            let user = response.data.data;

            // Send user data to getsitecontrol
            if (window.gsc) {
                window.gsc=window.gsc||function(){
                    (gsc.q=gsc.q||[]).push(arguments)
                };

                gsc('params', user)
            }

            // Merge premium access data allowed family members to original user data;
            if (config('feature.fitlap_plus')) {
                Api.get('/v2/access/premium/status').then(res => {
                    user.allowed_family_members = res.data.data.allowed_family_members;
                    user.fitlap_plus = res.data.data.fitlap_plus;
                    user.can_use_trial = res.data.data.can_use_trial;

                    if (!res.data.data.fitlap_plus) {
                        user.dark_mode = false;
                    }

                    if (user.dark_mode) {
                        document.body.classList.remove('lightmode');
                        document.body.classList.add('darkmode');
                    } else {
                        document.body.classList.remove('darkmode');
                        document.body.classList.add('lightmode');
                    }

                    dispatch(fetchUserSuccess(user));
                })
            } else {
                dispatch(fetchUserSuccess(user));
            }

            updateAmplitudeUser(user);

            if (callback != null) {
                callback(user);
            }
        }).catch(error => {
            dispatch(fetchUserFailure(error.response))
        });
    };
};

export const getUserPayments = () => {
    return (dispatch) => {
        return api.get('/v1/user/payments').then(response => {

            let payments = [];

            if (response.data.data.length > 0) {
                response.data.data.map(data => {
                    payments.push({
                        id: data.display_id,
                        price: data.amount + ' ' + data.currency,
                        date: moment(data.created_at).format('DD.MM.YYYY'),
                        status: data.status,
                        url: data.invoice_url
                    });
                })
            }

            dispatch(fetchUserPayments(payments))
        });
    };
};

/**
 *  Menu
 */

export const fetchMenuSuccess = (menu) => ({
    type: FETCH_MENU_SUCCESS,
    payload: {menu}
});

export const fetchMenuFailure = (error) => ({
    type: FETCH_MENU_FAILURE,
    payload: {error}
});

export const getUserMenu = (date, limit = 1) => {
    return (dispatch) => {
        return api.get('/v2/menu/compact?date=' + date + '&limit=' + limit).then(response => {
            const menu = response.data.data;

            // Set single meal kcal to cookies for food delivery e-shop
            if (!getCookie('food_amount')) {
                setCookie('food_amount', menu[0].meals[0].energy, 1);
            }

            dispatch(fetchMenuSuccess(menu));
        }).catch(error => {
            if (error && error.response && error.response.status == 412) {
                redirect('/setup');
            } else {
                dispatch(fetchMenuFailure(error.response))
            }
        });
    };
};

/**
 *  Diary
 */

export const fetchDiarySuccess = (diary) => ({
    type: FETCH_DIARY_SUCCESS,
    payload: {diary}
});

export const fetchDiaryFailure = (error) => ({
    type: FETCH_DIARY_FAILURE,
    payload: {error}
});

export const getUserDiary = (date) => {
    return (dispatch) => {
        return api.get('/v2/diary?date=' + date).then(response => {
            let data = response.data.data[0]['entries'];

            const isPlaceholder = (id) => {
                return id === config('placeholder_ingredient')
            }

            const formatEntryData = (entryData) => {
                entryData['carbohydrate'] = entryData.ingredients.carbohydrate;
                entryData['name'] = isPlaceholder(entryData.ingredient_id) ? entryData.recipe.original_name : entryData.ingredients.original_name;
                entryData['fat'] = entryData.ingredients.fat;
                entryData['protein'] = entryData.ingredients.protein;
                entryData['energy'] = entryData.ingredients.energy;

                if (isPlaceholder(entryData.ingredient_id)) {
                    entryData['amount'] = 100;
                }

                return entryData;
            }

            data.map(ingredient => {
                ingredient = formatEntryData(ingredient);

                if (ingredient.recipe) {
                    ingredient.recipe.meal_id = ingredient.meal_id;
                    ingredient.recipe.is_custom_energy = ingredient.is_custom_energy;
                }
            })

            dispatch(fetchDiarySuccess(data));
        }).catch(error => {
            dispatch(fetchDiaryFailure(error.response))

        });
    };
};


export const setMenuDate = (date) => ({
    type: UPDATE_MENU_DATE,
    payload: {date}
});

export const updateMenuEatenKcal = (data) => ({
    type: UPDATE_MENU_EATEN_KCAL,
    payload: {data}
});

export const getUserEatenKcal = () => {
    return (dispatch) => {
        return api.get('/v2/menu/daily/eaten').then(response => {
            if (response.data && response.data.data) {
                dispatch(updateMenuEatenKcal(response.data.data.eaten));
            }
        });
    };
};

export const fetchMenuReport = (report) => ({
    type: FETCH_MENU_REPORT,
    payload: {report}
});

export const getUserMenuReport = () => {
    return (dispatch) => {
        return api.get('/v2/menu/report').then(response => {
            // this gives an object with dates as keys
            const data = response.data.data.reduce((data, meal) => {
                const date = meal.date;

                if (!data[date]) {
                    data[date] = {};
                }

                data[date][meal.meal_type] = meal.has_eaten;

                return data;
            }, {});


            const meals = Object.keys(data).map((date) => {
                return {
                    date,
                    meals: data[date]
                };
            });

            dispatch(fetchMenuReport(meals));
        });
    };
};


/**
 *  Recipes
 */

export const fetchRecipesBegin = () => ({
    type: FETCH_RECIPES_BEGIN,
});

export const fetchRecipesSuccess = recipes => ({
    type: FETCH_RECIPES_SUCCESS,
    payload: {recipes}
});

export const fetchRecipesFailure = error => ({
    type: FETCH_RECIPES_FAILURE,
    payload: {error}
});

export const getRecipes = (params = '') => {
    return (dispatch) => {
        dispatch(fetchRecipesBegin());
        return api.get('/v2/recipes' + params).then(response => {
            dispatch(fetchRecipesSuccess(response.data))
        }).catch(error => {
            dispatch(fetchRecipesFailure(error.response))
        });
    };
};

export const fetchUserRecipesSuccess = user_recipes => ({
    type: FETCH_USER_RECIPES_SUCCESS,
    payload: {user_recipes}
});

export const fetchUserRecipesFailure = error => ({
    type: FETCH_USER_RECIPES_FAILURE,
    payload: {error}
});

export const getUserRecipes = () => {
    return (dispatch) => {
        return api.get('/v2/user/recipes').then(response => {
            dispatch(fetchUserRecipesSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchUserRecipesFailure(error.response))
        });
    };
};

export const fetchPopularRecipesSuccess = popular_recipes => ({
    type: FETCH_POPULAR_RECIPES_SUCCESS,
    payload: {popular_recipes}
});

export const fetchPopularRecipesFailure = error => ({
    type: FETCH_POPULAR_RECIPES_FAILURE,
    payload: {error}
});

export const getPopularRecipes = () => {
    return (dispatch) => {
        return api.get('/v2/recipes/popular').then(response => {
            dispatch(fetchPopularRecipesSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchPopularRecipesFailure(error.response))
        });
    };
};

export const fetchNewRecipesSuccess = new_recipes => ({
    type: FETCH_NEW_RECIPES_SUCCESS,
    payload: {new_recipes}
});

export const fetchNewRecipeFailure = error => ({
    type: FETCH_NEW_RECIPES_FAILURE,
    payload: {error}
});

export const getNewRecipes = () => {
    return (dispatch) => {
        return api.get('/v2/recipes?per_page=6&page=1&sort=new&order=desc').then(response => {
            dispatch(fetchNewRecipesSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchNewRecipeFailure(error.response))
        });
    };
};

export const setRecipesKeyword = keyword => ({
    type: SET_KEYWORD,
    payload: {keyword}
});

/**
 *  Access
 */

export const fetchAccessSuccess = access => ({
    type: FETCH_ACCESS_SUCCESS,
    payload: {access}
});

export const fetchAccessFailure = error => ({
    type: FETCH_ACCESS_FAILURE,
    payload: {error}
});

export const getAccess = () => {
    return (dispatch) => {
        return api.get('/v2/access/status').then(response => {
            let access = response.data.data ? response.data.data : response.data;

            trackUser({
                subscription_type: access.is_paid ? 'paid' : 'trial',
                subscription_status: access.status,
                subscription_start: access.begin_date,
                subscription_end: access.end_date
            });

            // Access expired
            if (access.status !== 'active') {
                trackEvent(access.is_paid ? 'subscription ended' : 'trial ended');
            }

            dispatch(fetchAccessSuccess(access))
        }).catch(error => {
            dispatch(fetchAccessFailure(error.response))
        });
    };
};

/**
 *  Family members
 */

export const fetchFamilyMembersSuccess = family_members => ({
    type: FETCH_FAMILY_MEMBERS_SUCCESS,
    payload: {family_members}
});

export const fetchFamilyMembersFailure = error => ({
    type: FETCH_FAMILY_MEMBERS_FAILURE,
    payload: {error}
});

export const getFamilyMembers = () => {
    return (dispatch) => {
        return api.get('/v1/family-members').then(response => {
            dispatch(fetchFamilyMembersSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchFamilyMembersFailure(error.response))
        });
    };
};

/**
 *  Weight & Goals
 */

export const fetchWeightLogSuccess = weight_log => ({
    type: FETCH_WEIGHT_LOG_SUCCESS,
    payload: {weight_log}
});

export const fetchWeightLogFailure = error => ({
    type: FETCH_WEIGHT_LOG_FAILURE,
    payload: {error}
});

export const getWeightLog = () => {
    return (dispatch) => {
        return api.get('/v1/user/weight-logs?all=true').then(response => {
            dispatch(fetchWeightLogSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchWeightLogFailure(error.response))
        });
    };
};

export const fetchGoalSuccess = goal => ({
    type: FETCH_GOAL_SUCCESS,
    payload: {goal}
});

export const fetchGoalFailure = error => ({
    type: FETCH_GOAL_FAILURE,
    payload: {error}
});

export const getGoal = () => {
    return (dispatch) => {
        return api.get('/v1/user/goal').then(response => {
            dispatch(fetchGoalSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchGoalFailure(error.response))
        });
    };
};

export const fetchSizesLogSuccess = sizes => ({
    type: FETCH_SIZES_LOG_SUCCESS,
    payload: {sizes}
});

export const fetchSizesLogFailure = error => ({
    type: FETCH_SIZES_LOG_FAILURE,
    payload: {error}
});

export const getSizesLog = () => {
    return (dispatch) => {
        return api.get('/v1/user/body-sizes').then(response => {
            dispatch(fetchSizesLogSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchSizesLogFailure(error.response))
        });
    };
};

/**
 *  Freeware ingredients
 */

export const fetchFreewareSuccess = freeware => ({
    type: FETCH_FREEWARE_SUCCESS,
    payload: {freeware}
});

export const fetchFreewareFailure = error => ({
    type: FETCH_FREEWARE_FAILURE,
    payload: {error}
});

export const getFreewareIngredients = () => {
    return (dispatch) => {
        return api.get('/v1/ingredients/freeware').then(response => {
            dispatch(fetchFreewareSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchFreewareFailure(error.response))
        });
    };
};

/**
 *  Shopping List
 */

export const shoppingListSuccess = shopping_list => ({
    type: FETCH_SHOPPING_LIST_SUCCESS,
    payload: {shopping_list}
});

export const shoppingListFailure = error => ({
    type: FETCH_SHOPPING_LIST_FAILURE,
    payload: {error}
});

export const shoppingListCategories = data => ({
    type: FETCH_SHOPPING_LIST_CATEGORIES,
    payload: {data}
});


export const getShoppingList = () => {
    return (dispatch) => {
        return api.get('/v2/shopping-list').then(response => {
            dispatch(shoppingListSuccess(response.data.data))
        }).catch(error => {
            dispatch(shoppingListFailure(error.response))
        });
    };
};

export const updateShoppingList = (status, id) => {
    return (dispatch) => {
        return api.post('/v2/shopping-list/toggle/' + id, {status: status}).then(response => {
            dispatch(shoppingListSuccess(response.data.data))
        }).catch(error => {
            dispatch(shoppingListFailure(error.response))
        });
    };
};

export const updateShoppingListByDate = (date, status) => {
    return (dispatch) => {
        return api.post('/v1/shopping-list/toggle', {date: date, status: status}).then(response => {
            dispatch(shoppingListSuccess(response.data.data))
        }).catch(error => {
            dispatch(shoppingListFailure(error.response))
        });
    };
};

export const updateShoppingListIngredients = (status, id) => {
    return (dispatch) => {
        if (status) {
            return api.post('/v2/shopping-list/ingredient/' + id).then(response => {
                dispatch(shoppingListSuccess(response.data.data))
            }).catch(error => {
                dispatch(shoppingListFailure(error.response))
            });
        } else {
            return api.delete('/v2/shopping-list/ingredient/' + id).then(response => {
                dispatch(shoppingListSuccess(response.data.data))
            }).catch(error => {
                dispatch(shoppingListFailure(error.response))
            });
        }
    };
};

export const updateShoppingListItems = (status, id) => {
    return (dispatch) => {
        return api.post('/v2/shopping-list/items/' + id, {checked: status}).then(response => {
            dispatch(shoppingListSuccess(response.data.data))
        }).catch(error => {
            dispatch(shoppingListFailure(error.response))
        });
    };
};

export const getShoppingListCategories = () => {
    return (dispatch) => {
        return api.get('/v1/ingredients/categories').then(response => {
            dispatch(shoppingListCategories(response.data))
        }).catch(error => {
            dispatch(shoppingListFailure(error.response))
        });
    };
};

export const cheatMealAvailableSuccess = is_cheat_meal_available => ({
    type: IS_CHEAT_MEAL_AVAILABLE_SUCCESS,
    payload: {is_cheat_meal_available}
});

export const cheatMealAvailableError = error => ({
    type: IS_CHEAT_MEAL_AVAILABLE_FAILURE,
    payload: {error}
});

export const isCheatMealAvailable = () => {
    return (dispatch) => {
        return api.get('/v1/menu/is-cheat-available').then(response => {
            dispatch(cheatMealAvailableSuccess(response.data.data))
        }).catch(error => {
            dispatch(cheatMealAvailableError(error.response))
        });
    };
};

/*
 * Daily activities
 */

export const dailyActivitiesSuccess = daily_activities => ({
    type: FETCH_DAILY_ACTIVITIES_SUCCESS,
    payload: {daily_activities}
});

export const dailyActivitiesError = error => ({
    type: FETCH_DAILY_ACTIVITIES_FAILURE,
    payload: {error}
});

export const getDailyActivites = (date) => {
    return (dispatch) => {
        return api.get('/v2/menu/daily/activities?date=' + date).then(response => {
            dispatch(dailyActivitiesSuccess(response.data.data))
        }).catch(error => {
            dispatch(dailyActivitiesError(error.response))
        });
    };
};

export const physicalActivitiesSuccess = physical_activities => ({
    type: FETCH_PHYSICAL_ACTIVITIES_SUCCESS,
    payload: {physical_activities}
});

export const physicalActivitiesError = error => ({
    type: FETCH_PHYSICAL_ACTIVITIES_FAILURE,
    payload: {error}
});

export const getPhysicalActivities = (date) => {
    return (dispatch) => {
        return api.get('/v2/family-members/physical-activity' + (date ? '?date=' + date : '')).then(response => {
            let data = [];

            response.data.data.map(participant => {
                if (participant.physical_activity) {
                    data.push(participant.id)
                }
            });

            dispatch(physicalActivitiesSuccess(data))
        }).catch(error => {
            dispatch(physicalActivitiesError(error.response))
        });
    };
};

/*
 * Notifications
 */

export const notificationsSuccess = notifications => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: {notifications}
});

export const notificationsError = error => ({
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: {error}
});

export const getNotifications = () => {
    return (dispatch) => {
        return api.get('/v2/notifications').then(response => {
            dispatch(notificationsSuccess({
                notifications: response.data.data,
                count: response.data.newNotificationsCount,
                lastIndex: response.data.lastIndex
            }))
        }).catch(error => {
            dispatch(notificationsError(error.response))
        });
    };
};

/*
 * Billing plan
 */

export const productsSuccess = products => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: {products}
});

export const productsError = error => ({
    type: FETCH_PRODUCTS_FAILURE,
    payload: {error}
});

export const getProducts = () => {
    return (dispatch) => {
        return api.get('/v2/billing-plans/default').then(response => {
            dispatch(productsSuccess(response.data.data))
        }).catch(error => {
            dispatch(productsError(error.response))
        });
    };
};

/*
 *  Helpcrunch chat
 */

export const showChat = value => ({
    type: SHOW_CHAT,
    payload: value
});

/*
 *  Blog Author
 */

export const favoriteAuthorsBegin = () => ({
    type: FETCH_AUTHORS_BEGIN,
});

export const favoriteAuthorsSuccess = authors => ({
    type: FETCH_AUTHORS_SUCCESS,
    payload: {authors}
});

export const favoriteAuthorsError = error => ({
    type: FETCH_AUTHORS_FAILURE,
    payload: {error}
});

export const getFavoriteAuthors = () => {
    return (dispatch) => {
        return api.get('/v1/user/favorite-authors').then(response => {
            dispatch(favoriteAuthorsSuccess(response.data.data))
        }).catch(error => {
            dispatch(favoriteAuthorsError(error.response))
        });
    };
};

export const FetchBlogFeedBegin = () => ({
    type: FETCH_BLOG_FEED_BEGIN,
});

export const BlogFeedSuccess = feed => ({
    type: FETCH_BLOG_FEED_SUCCESS,
    payload: {feed}
});

export const BlogFeedError = error => ({
    type: FETCH_BLOG_FEED_FAILURE,
    payload: {error}
});

export const getBlogFeed = (group, params = '') => {
    return (dispatch) => {
        dispatch(FetchBlogFeedBegin());

        // Default blog feed
        let url = '/v2/blog/posts/feed?' + params;

        // Load custom group posts
        if (group !== 'feed') {
            url = `/v1/blog/posts?group=${group}&${params}&per_page=8`
        }

        return api.get(url).then(response => {
            dispatch(BlogFeedSuccess(response.data))
        }).catch(error => {
            dispatch(BlogFeedError(error.response))
        });
    };
};

/*
 *  Blog feed filter
 */

export const blogFilter = value => ({
    type: BLOG_FILTER,
    payload: value
});

/*
 *  Blog feed keyword
 */

export const blogKeyword = value => dispatch => {
    dispatch({
        type: BLOG_KEYWORD,
        payload: value
    });

    return Promise.resolve();
};


/*
 *  Get banners from Wp API
 */

export const BannersSuccess = banners => ({
    type: BANNERS,
    payload: banners
});

export const getBanners = () => {
    if (!config('feature.banner')) {
        return () => {};
    }

    return (dispatch) => {
        return api.loadBanners().then(response => {
            dispatch(BannersSuccess(response.data.acf))
        }).catch(error => {
            console.log(error);
        });
    };
};


export const fetchWaterLogSuccess = water_log => ({
    type: FETCH_WATER_LOG_SUCCESS,
    payload: {water_log}
});

export const fetchWaterLogFailure = error => ({
    type: FETCH_WATER_LOG_FAILURE,
    payload: {error}
});

export const getWaterLog = (date) => {
    return (dispatch) => {
        return api.get('/v1/user/water-logs', {date: date}).then(response => {
            dispatch(fetchWaterLogSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchWaterLogFailure(error.response))
        });
    };
};

export const fetchDailyTaskSuccess = daily_task => ({
    type: FETCH_DAILY_TASK_SUCCESS,
    payload: {daily_task}
});

export const fetchDailyTaskFailure = error => ({
    type: FETCH_DAILY_TASK_FAILURE,
    payload: {error}
});

export const getDailyTask = (date) => {
    return (dispatch) => {
        return api.get('/v2/user/tasks', {date: date}).then(response => {
            dispatch(fetchDailyTaskSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchDailyTaskFailure(error.response))
        });
    };
};


/*
** Levels
 */

export const fetchUserLevelsSuccess = levels => ({
    type: FETCH_USER_LEVELS_SUCCESS,
    payload: {levels}
});

export const fetchUserLevelsFailure = error => ({
    type: FETCH_USER_LEVELS_FAILURE,
    payload: {error}
});

export const getUserLevels = () => {
    return (dispatch) => {
        return api.get('/v2/user/levels').then(response => {
            dispatch(fetchUserLevelsSuccess(response.data.data))
        }).catch(error => {
            dispatch(fetchUserLevelsFailure(error.response))
        });
    };
};

// CUSTOM UNITS

export const customUnitsSuccess = units => ({
    type: CUSTOM_UNITS,
    payload: {units}
});

export const getCustomUnits = () => {
    return (dispatch) => {
        return api.get('/v2/custom-units').then(response => {
            dispatch(customUnitsSuccess(response.data))
        });
    };
};

// APP NAV MENU

export const updateAppNavMenu = values => ({
    type: APP_NAV_MENU,
    payload: {values}
});


/*
 *  Advent calender
 */

export const updateAdventCalender = payload => ({
    type: ADVENT_CALENDER,
    payload: payload
});

export const getAdventCalender = () => {
    return (dispatch) => {
        return api.get('/v2/user/advent-calendar').then(response => {
            dispatch(updateAdventCalender(response.data.data))
        });
    };
};

// Quick start for new users

export const updateQuickStart = payload => ({
    type: FETCH_QUICK_START_SUCCESS,
    payload: payload
});

export const getStartGuide = () => {
    return (dispatch) => {
        return api.get('/v2/start-guides').then(response => {
            dispatch(updateQuickStart(response.data))
        });
    };
};
